import React, { useEffect, useState } from "react";
import { getScoket } from "../../commom/socket";
import StartingEndorseNotification from "../Alert/Endorse";
import EndingEndorseNotification from "../Alert/EndorseEnd";

const NEW_EVENT = 0;
const FINISHED_EVENT = 1;

const io = getScoket();
const NewOverlay = ({ match }) => {
  const [endorseList, setEndorseList] = useState([
    { viewer: "Preview", goal: 999, amount: 999, eventType: 0 },
  ]);
  const [finishedEnorsed, setFinished] = useState([]);

  const messageHandler = ({ finished, type, newEndorse }) => {
    if (type === NEW_EVENT) {
      setEndorseList((endorses) => [...endorses, newEndorse]);
    }
    if (type === FINISHED_EVENT) {
      if (finished && finished.length) {
        setFinished((finishedEndorses) => [...finishedEndorses, ...finished]);
      }
    }
  };

  useEffect(() => {
    const socket = io.sails.connect();
    socket.get(`/websource/overlay/${match.params.id}`, function (data, jwr) {
      if (jwr.error) {
        console.error(
          "Could not subscribe to the server notifications: " + jwr.error
        );
        //setSocketError(SOKET_STATUS.ERROR);
        return;
      }
      const handler = (message) => {
        messageHandler(message);
      };
      socket.on("user", handler);
    });
    return () => socket.off();
  }, [match.params.id]);
  if (finishedEnorsed.length)
    return (
      <EndingEndorseNotification
        key={`${finishedEnorsed[0].viewer}-${finishedEnorsed[0].goal}`}
        {...finishedEnorsed[0]}
        setList={setFinished}
      />
    );
  if (endorseList.length)
    return (
      <StartingEndorseNotification
        key={`${endorseList[0].viewer}-${endorseList[0].goal}`}
        {...endorseList[0]}
        setList={setEndorseList}
      />
    );
  return null;
};

export default NewOverlay;
