import React from "react";
import Overlay from "./components/Overlay";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import EndorsePage from "./components/EndorsePage";
import ErrorPage from "./components/ErrorPages/404";
import EndorseEnd from "./components/Alert/EndorseEnd";
import Bar from "./components/Bar";

export default function Root() {
  return (
    <Router>
      <Switch>
        <Route
          key={`endorse-page`}
          path="/endorse/:id"
          component={EndorsePage}
        />
        <Route key="overlay-page" path="/overlay/:id" component={Overlay} />
        <Route
          path="/404"
          render={() => (
            <ErrorPage
              errorNumber="Not Found :("
              errorText="Sorry, this page does not exist"
            />
          )}
        />
        <Route
          path="/offline"
          render={() => (
            <ErrorPage
              errorNumber="Streamer is offline*"
              errorText="* betcha accout is not active at the moment"
            />
          )}
        />
        <Route
          path="/thanks"
          render={() => (
            <ErrorPage
              errorNumber="Thank You :)"
              errorText="Your challenge has been set, get back to the stream!"
            />
          )}
        />
        <Route
          path="/transaction-failed"
          render={() => (
            <ErrorPage
              errorNumber="Server Error :("
              errorText="Your purchase encoutered some issues. Please try again later"
            />
          )}
        />
        <Route // This is a preview for the alert
          path="/endorse"
          render={() => (
            <EndorseEnd
              setList={() => console.log("a")}
              viewer="TEST"
              amount={4000}
              eventType={0}
              goal={2}
            />
          )}
        />
        <Route key="bar" path="/bar/:id" component={Bar} />
        <Route
          render={() => (
            <ErrorPage
              errorNumber="Not Found :("
              errorText="Sorry, this page does not exist"
            />
          )}
        />
      </Switch>
    </Router>
  );
}
