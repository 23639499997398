import React, { useState } from "react";
import { PayPalButton } from "react-paypal-button-v2";
import { Redirect } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SERVER_URL } from "../../commom/const";
import { Grid } from "@material-ui/core";

const PAYAPL_STATUS = {
  ERROR: "error",
  LOADING: "lodaing",
  FINISHED: "done",
  INIT: "init",
};

const CLIENT_ID =
  "ARHTzN5Ekm6zsQWGwYlrHnbBYN34OON8pc3E0fJrgzCtWmoO-N_rCgOUkYqgtiaWS9Hl3cODobUG8gKQ";
const PayPal = ({
  amount,
  viewer,
  message,
  goal,
  eventType,
  nickname,
  setPaypalLoaded,
  // viewer pay commision
  checkedCommission
}) => {
  const [paypalProcessing, setPaypalProcessing] = useState(PAYAPL_STATUS.INIT);

  const authorize = (data, actions) => {
    setPaypalProcessing(PAYAPL_STATUS.LOADING);
    actions.order.authorize().then(function (authorization) {
      // Get the authorization id
      var authorizationID =
        authorization.purchase_units[0].payments.authorizations[0].id;
      // Call your server to validate and capture the transaction
      try {
        fetch(`${SERVER_URL}/endorse/${nickname}`, {
          method: "post",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            orderID: data.orderID,
            authID: authorizationID,
            viewer,
            amount,
            message,
            eventType,
            streamerCommission: !checkedCommission,
            goal,
          }),
        });
      } catch (err) {
        setPaypalProcessing(PAYAPL_STATUS.ERROR);
      }
      setPaypalProcessing(PAYAPL_STATUS.FINISHED);
    });
  };
  const goToErrorPage = () => <Redirect to="/transaction-failed" />;
  switch (paypalProcessing) {
    case PAYAPL_STATUS.ERROR:
      return <Redirect to="/transaction-failed" />;
    case PAYAPL_STATUS.FINISHED:
      return <Redirect to="/thanks" />;
    default:
      return (
        <Grid container direction="column" alignItems="center">
          {paypalProcessing === PAYAPL_STATUS.LOADING && (
            <Grid item>
              <CircularProgress />
            </Grid>
          )}
          <Grid item>
            <PayPalButton
              shippingPreference="NO_SHIPPING"
              amount={checkedCommission ? amount * 1.1 : amount}
              onError={goToErrorPage}
              onApprove={authorize}
              onButtonReady={() => setPaypalLoaded(true)}
              options={{
                clientId: CLIENT_ID,
                currency: "USD",
                intent: "authorize",
              }}
            />
          </Grid>
        </Grid>
      );
  }
};

export default PayPal;
