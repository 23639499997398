import socketIOClient from "socket.io-client";
import sailsIOClient from "sails.io.js";
import { SERVER_URL } from '../../commom/const';

const io = sailsIOClient(socketIOClient);
io.sails.transports = ['websocket'];
io.sails.url = SERVER_URL
io.sails.autoConnect = false;
io.sails.reconnection = true;
io.sails.reconnectionDelay = 500;
io.sails.reconnectionAttempts = 360;
export const getScoket = () => io