import React, { useState } from "react";
import EndorsePreview from '../Alert/EndorseEnd'
import Dialog from "@material-ui/core/Dialog";
import Alert from "@material-ui/lab/Alert";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import PayPalButton from "../PaymentButton/Button";
import CustomizedDialogs from "./info";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PaymentModel = ({
  handleClose,
  open,
  amount,
  viewer,
  eventType,
  nickname,
  message,
  goal,
}) => {
  const [paypalLoaded, setPaypalLoaded] = useState(false);
  const [checkedCommission, setCheckedCommission] = React.useState(false);

  const handleCommissionChange = (event) => {
    setCheckedCommission(event.target.checked);
  };

  return (
    <div>
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Alert severity="info">
          Betcha takes 10% of every successful challenge completed by the
          streamer.
        </Alert>
        <div className="infoMod">
          <CustomizedDialogs />
        </div>
        <DialogTitle
          id="alert-dialog-slide-title"
          style={{ textAlign: "center" }}
        >
          {`Submitting Endorsement to ${nickname}`}
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={4}
            // alignItems="center"
            justify="space-around"
            direction="column"
          >
            <Grid item>
              <EndorsePreview
                viewer={viewer}
                message={message}
                goal={goal}
                eventType={eventType}
                amount={amount}
              />
            </Grid>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleCommissionChange}
                  icon={<FavoriteBorder />}
                  checkedIcon={<Favorite />}
                  name="checkedH"
                />
              }
              label="I would also like to pay for the fee"
            />
            <Grid item alignItems="center">
              {!paypalLoaded && <CircularProgress />}
              <PayPalButton
                nickname={nickname}
                amount={amount}
                viewer={viewer}
                checkedCommission={checkedCommission}
                goal={goal}
                message={message}
                eventType={eventType}
                setPaypalLoaded={setPaypalLoaded}
              />
            </Grid>
            <Grid item alignItems="center"></Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PaymentModel;
