import React from "react";
import AppBar from "@material-ui/core/AppBar";
import logo from "../../images/logo-strip.png";

const AppMenu = () => {
  return (
    <AppBar position="static">
      <a href="https://www.bucket.gg">
        <img alt="logo" height="9%" width="9%" src={logo} className="logo" />
      </a>
    </AppBar>
  );
};

export default AppMenu;
