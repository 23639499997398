import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Fade from "react-reveal/Fade";

const ErrorPage = ({ errorNumber, errorText }) => {
  return (
    <div
      style={{
        background:
          "linear-gradient(148deg, rgba(0,0,0,1) 0%, rgba(23,32,61,1) 61%)",
      }}
    >
      <Grid
        container
        spacing={4}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh" }}
      >
        <Fade>
          <Grid item xs={12}>
            <Typography style={{ color: "white" }} variant="h1">
              {errorNumber}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ color: "white" }} variant="h3">
              {errorText}
            </Typography>
          </Grid>
        </Fade>
      </Grid>
    </div>
  );
};

export default ErrorPage;
