import React, { useState, useEffect } from "react";
import SplitText from "react-pose-text";
import "./styles.css";

const TIME_FOR_ANIMATION = 6000;
const charPoses = {
  exit: { opacity: 0, y: 20 },
  enter: {
    opacity: 1,
    y: 0,
    delay: ({ charIndex }) => charIndex * 100,
  },
};

const AnimaatedText = ({ text, constant, updateQueue }) => {
  const [isVisibale, setVisablity] = useState(false);
  useEffect(() => {
    if (text.length) setVisablity(true);
  }, [text]);
  useEffect(() => {
    if (text.length && !constant) {
      const timer = setInterval(() => {
        debugger;
        setVisablity(false);
        if (updateQueue) updateQueue();
      }, TIME_FOR_ANIMATION);
      return () => {
        clearInterval(timer);
      };
    }
  }, [text]);

  return (
    <div className="container">
      <SplitText
        initialPose="exit"
        pose={isVisibale ? "enter" : "exit"}
        charPoses={charPoses}
      >
        {text}
      </SplitText>
    </div>
  );
};

export default AnimaatedText;
