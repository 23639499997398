import { createMuiTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#101427'
    }
  }
});
export const useStyles = makeStyles(theme => ({
  tabs: {
      marginBottom: '10px'
  },
  app: {
    overflowY: "visible",
    overflowX: "hidden",
    background: 'radial-gradient(ellipse at center, #17203d 20%,#000000 96%);',
    height: "100vh",
    flexGrow: 1
  },
  root: {
    flexGrow: 1
  },
  content: {
    paddingTop: "25px",
    paddingBottom: "80px",
    marginBottom: "50px"
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  }
}));
