import React, { useEffect, useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { motion } from "framer-motion";
import "./styles.css";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles({
  logo: {
    height: "26.8px",
    width: "115.53px",
    marginBottom: "-3%",
  },
  root: {
    background: `linear-gradient(148deg, rgba(0,0,0,1) 0%,
    rgba(38,38,38,1) 61%)`,
    backgroundSize: "cover",
    height: "143px",
    width: "287px",
    borderRadius: "4px",
  },
  media: {
    height: 140,
  },
});

const EndorseEvent = ({
  viewer,
  goal,
  amount,
  setList,
  eventType,
}) => {
  const classes = useStyles();

  const nicknameClass = useCallback(() => {
    if (!viewer) return null;
    const viewerLength = viewer.length;
    switch (true) {
      case viewerLength < 10:
        return "name0";
      case viewerLength < 11:
        return "name10";
      default:
        return "name11";
    }
  }, [viewer]);

  const amountClass = useCallback(() => {
    if (!amount) return [null, null];
    const amountLength = amount.toString().length;
    switch (true) {
      case amountLength < 2:
        return ["amount0", "colline0"];
      case amountLength < 3:
        return ["amount2", "colline2"];
      case amountLength < 4:
        return ["amount3", "colline3"];
      default:
        return ["amount4", "colline4"];
    }
  }, [amount]);

  const goalClass = () => {
    if (!goal) return null;
    const goalLength = goal.toString().length;
    switch (true) {
      case goalLength < 2:
        return "goal0";
      case goalLength < 3:
        return "goal2";
      default:
        return "goal3";
    }
  };
  const [animState, setAnimState] = useState("visible");
  const variantsCard = {
    init: { x: -1500, opacity: 0 },
    visible: { x: 0, y: 20, opacity: 1, transition: { duration: 1 } },
    hidden: { x: -1500, opacity: 0, transition: { duration: 0.5 } },
  };
  const variantLogo = {
    init: { x: -1500, opacity: 0 },
    visible: { x: -85, scale: 1, opacity: 1, transition: { duration: 1.3 } },
    hidden: { x: -1500, opacity: 1, transition: { duration: 0.5 } },
  };

  useEffect(() => {
    setTimeout(() => setAnimState("hidden"), 7000);
    setTimeout(() => setList((list) => list.slice(1)), 8000);
  }, [setList, setAnimState]);
  return (
    <motion.div variants={variantsCard} animate={animState} initial="init">
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item>
          <motion.div variants={variantLogo} animate={animState} initial='init'>
            <img
              alt="logo"
              src={require("../../images/logo-strip1.png")}
              className={classes.logo}
            />
          </motion.div>
        </Grid>
        <Grid item>
          <Paper className={classes.root}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={6}>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs={12} className="challenged-you">
                    A New Challenge
                  </Grid>
                  <Grid item xs={12} className="for">
                    From
                  </Grid>
                  <Grid item xs={12} className={nicknameClass()}>
                    {viewer}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} className={eventType ? "trophy" : "skull"}>
                <img
                  alt=""
                  width={eventType ? 93.15 : 96}
                  height={eventType ? 87.4 : 116}
                  src={require(`../../images/${
                    eventType ? "trophy" : "skull"
                  }-new-2.png`)}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
            >
              <Grid item className="rowline">
                <hr
                  style={{
                    color: "#00caef",
                    backgroundColor: "#00caef",
                    width: "265px",
                    height: "0px",
                    borderColor: "#00caef",
                    opacity: 0.3,
                  }}
                />
              </Grid>
              <Grid container direction="row">
                <Grid item xs={4} className={amountClass()[0]}>
                  {`$${amount}`}
                </Grid>
                <Grid item xs={2} className={amountClass()[1]}>
                  <hr
                    style={{
                      color: "#00caef",
                      backgroundColor: "#00caef",
                      height: "26px",
                      borderColor: "#00caef",
                      opacity: 0.3,
                      width: "1px",
                    }}
                  />
                </Grid>
                <Grid item xs={4} className={goalClass()}>
                  {`${eventType ? "WINS" : "DOWN "}X ${goal}`}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </motion.div>
  );
}

export default EndorseEvent;
