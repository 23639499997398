import React, { useEffect, useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { motion } from "framer-motion";
import "./styles.css";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles({
  logo: {
    height: "26.8px",
    width: "115.53px",
    marginBottom: "-4%",
  },
  root: {
    background: `linear-gradient(148deg, rgba(0,0,0,1) 0%,
    rgba(38,38,38,1) 61%)`,
    backgroundSize: "cover",
    height: "162px",
    width: "287px",
    borderRadius: "4px",
  },
  media: {
    height: 140,
  },
});

const EndingEndorse = ({
  goal,
  amount,
  viewer,
  message,
  setList,
  eventType,
}) => {
  const classes = useStyles();

  const nicknameClass = useCallback(() => {
    if (!viewer) return null;
    const viewerLength = viewer.length;
    switch (true) {
      case viewerLength < 6:
        return "name-done0";
      case viewerLength < 8:
        return "name-done6";
      case viewerLength < 11:
        return "name-done8";
      default:
        return "name-done11";
    }
  }, [viewer]);
  const [animState, setAnimState] = useState("visible");
  const variantsCard = {
    init: { x: -1500, opacity: 0 },
    visible: { x: 0, y: 20, opacity: 1, transition: { duration: 1 } },
    hidden: { x: -1500, opacity: 0, transition: { duration: 0.5 } },
  };
  const variantLogo = {
    init: { x: -1500, opacity: 0 },
    visible: { x: -85, scale: 1, opacity: 1, transition: { duration: 1.3 } },
    hidden: { x: -1500, opacity: 1, transition: { duration: 0.5 } },
  };

  useEffect(() => {
    setTimeout(() => setAnimState("hidden"), 7000);
    setList && setTimeout(() => setList((list) => list.slice(1)), 8000);
  }, [setList, setAnimState]);
  return (
    <motion.div variants={variantsCard} animate={animState} initial="init">
      <Grid container direction="column" justify="center" alignItems="center">
        <motion.div variants={variantLogo} animate={animState} initial="init">
          <Grid item xs={11}>
            <img
              alt="logo"
              src={require("../../images/logo-strip1.png")}
              className={classes.logo}
            />
          </Grid>
        </motion.div>
        <Grid xs={11} item>
          <Paper className={classes.root}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={6}>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  className="data-done"
                >
                  <Grid item xs={11} className={nicknameClass()}>
                    <img
                      alt="v"
                      width={25}
                      height={23}
                      src={require("../../images/v-circle1.png")}
                    />
                    {viewer}
                  </Grid>
                  <Grid item xs={12} className="support">
                    Support With
                  </Grid>
                  <br></br>
                  <Grid
                    item
                    xs={3}
                    className={
                      amount.toString().length < 4
                        ? "amount-done0"
                        : "amount-done4"
                    }
                  >
                    {`$${amount}`}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={3}
                className={eventType ? "wins-done" : "kills-done"}
              >
                <img
                  alt="event"
                  width={eventType ? 68 : 65.0496}
                  height={eventType ? 63.7 : 78.6016}
                  src={require(`../../images/${
                    eventType ? "trophy" : "skull"
                  }-new-21.png`)}
                />
                {`${eventType ? "WINS" : "Down "}X ${goal}`}
              </Grid>
            </Grid>
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
            >
              <Grid item className="rowline-done">
                <hr
                  style={{
                    color: "#00caef",
                    backgroundColor: "#00caef",
                    width: "265px",
                    height: "0px",
                    borderColor: "#00caef",
                    opacity: 0.3,
                  }}
                />
              </Grid>
              <Grid item xs={12} className="message">
                {message}
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </motion.div>
  );
};

export default EndingEndorse;
