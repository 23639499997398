import React from 'react';
import './styles.scss';

const LiquidProgressBar = ({progress}) => (
  <div className="container">
    <section>
      <article>
        <div className="chart">
          <div className={`bar bar-${progress} betcha-color`}>
            <div className="face top">
              <div className="growing-bar"></div>
            </div>
            <div className="face side-0">
              <div className="growing-bar"></div>
            </div>
            <div className="face floor">
              <div className="growing-bar"></div>
            </div>
            <div className="face side-a"></div>
            <div className="face side-b"></div>
            <div className="face side-1">
              <div className="growing-bar"></div>
            </div>
          </div>
        </div>
      </article>
    </section>
  </div>
);

export default LiquidProgressBar