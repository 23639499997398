import React, { useState, useEffect, useCallback } from "react";
import CountUp from "react-countup";
import LiquidProgressBar from "../GlowingProgress";
import { Grid, Paper } from "@material-ui/core";
import { groupBy, omit } from "lodash";
import { getScoket } from "../../commom/socket";

import "../Alert/styles.css";
import "./styles.css";

const skull = require("../../images/skull-still1.png");
const trophy = require("../../images/trophy-still1.png");
const logoStrip = require("../../images/logo-url1.png");
const coins = require("../../images/coins1.png");
const NEW_EVENT = 0;
const FINISHED_EVENT = 1;
const io = getScoket();

const NewBar = ({ match }) => {
  const [endorseList, setEndorseList] = useState({});
  const [groupGoal, setGroupGoal] = useState(null);
  const [balance, setBalance] = useState(0);
  const [stacked, setStacked] = useState(0);

  const messageHandler = ({ finished, inProgress, type, newEndorse }) => {
    if (type === NEW_EVENT) {
      const delta = newEndorse.goal - newEndorse.currentnumber;
      const newEndorseWithDelta = {
        ...newEndorse,
        delta,
      };
      if (parseInt(newEndorse.eventType) === 0) {
        setGroupGoal((goal) => (delta < goal ? delta : goal));
      }
      setStacked((prevStack) => prevStack + newEndorse.amount);
      setEndorseList((endorses) => ({
        ...endorses,
        [delta]: endorses[delta]
          ? [...endorses[delta], newEndorseWithDelta]
          : [newEndorseWithDelta],
      }));
    }
    if (type === FINISHED_EVENT) {
      const endorsesWithDelta = [...inProgress, ...finished].map((item) => ({
        ...item,
        delta: item.goal - item.currentnumber,
      }));
      const groupByDelta = groupBy(endorsesWithDelta, "delta");

      setEndorseList(groupByDelta);
    }
  };

  useEffect(() => {
    const deltas = Object.keys(endorseList).filter((key) =>
      endorseList[key].some((endorse) => parseInt(endorse.eventType) === 0)
    );
    if (!groupGoal) setGroupGoal(deltas[0]);
    if (endorseList[0]) {
      const anyKillEvent = endorseList[0].some(
        (endorse) => parseInt(endorse.eventType) === 0
      );
      const earnedMoney = endorseList[0].reduce(
        (accumulator, endorse) => accumulator + endorse.amount,
        0
      );
      setBalance((balance) => balance + earnedMoney);
      if (anyKillEvent) {
        setTimeout(() => {
          deltas[1] ? setGroupGoal(deltas[1]) : setGroupGoal(null);
          setEndorseList((obj) => omit(obj, ["0"]));
        }, 1500);
      } else setEndorseList((obj) => omit(obj, ["0"]));
    }
  }, [endorseList]);

  const nextWin = useCallback(() => {
    if (!endorseList[1]) return 0;
    const winArray = endorseList[1].filter((item) => item.eventType === 1);
    if (!winArray.length) return 0;
    return winArray.reduce(
      (accumulator, endorse) => accumulator + endorse.amount,
      0
    );
  }, [endorseList]);

  const nextKillLeft = useCallback(() => {
    if (!Object.keys(endorseList).length) return undefined;
    const killsDeltas = Object.keys(endorseList).filter((key) =>
      endorseList[key].some((endorse) => parseInt(endorse.eventType) === 0)
    );

    return Math.min(...killsDeltas);
  }, [endorseList]);

  const nextProfit = useCallback(() => {
    if (!Object.keys(endorseList).length) return 0;
    const KillsDelta = Object.keys(endorseList).filter((key) =>
      endorseList[key].some((endorse) => parseInt(endorse.eventType) === 0)
    );
    const minDelta = Math.min(...KillsDelta);
    if (minDelta === Infinity) return 0;
    return endorseList[minDelta]
      .filter((endorse) => endorse.eventType === 0)
      .reduce((accumulator, endorse) => accumulator + endorse.amount, 0);
  }, [endorseList]);

  const winMoneyClass = () => {
    const winLength = nextWin().toString().length;
    switch (true) {
      case winLength < 4:
        return "barwin3";
      case winLength < 5:
        return "barwin4";
      default:
        return "barwin5";
    }
  };

  const balanceClass = useCallback(
    () => `balance${balance.toString().length} glow`,
    [balance]
  );

  const nextKillProfit = useCallback(
    () => `nextprofit${nextProfit().toString().length}`,
    [nextProfit]
  );

  useEffect(() => {
    const socket = io.sails.connect();
    socket.get(`/websource/overlay/${match.params.id}`, function (data, jwr) {
      if (jwr.error) {
        console.error(
          "Could not subscribe to the server notifications: " + jwr.error
        );
        return;
      }
      const handler = (message) => {
        messageHandler(message);
      };
      socket.on("user", handler);
    });
    return () => socket.off();
  }, [match.params.id]);
  const nextKillForEarning = nextKillLeft();

  return (
    <>
      <img
        alt="logo"
        height="42px"
        width="142px"
        src={logoStrip}
        className="logo-bar"
      />
      <Paper
        style={{
          background: `linear-gradient(148deg, rgba(0,0,0,1) 0%,
      rgba(38,38,38,1) 61%)`,
      // background: `linear-gradient(148deg, rgba(0,0,0,1) 0%,
      // rgba(23,32,61,1) 61%)`,
          height: "165px",
          marginTop: "10px",
          marginLeft: "10px",
          opacity: 1,
          width: "378px",
        }}
      >
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid style={{ marginTop: "0px", marginLeft: "1%" }} item xs={2}>
            <img
              alt="trophy"
              src={trophy}
              style={{ marginTop: "6px", width: "72.5px", height: "70.4px" }}
            />
          </Grid>
          <Grid item xs={6} className="bar-wins-data">
            Next Win:
            <CountUp
              start={0}
              end={nextWin()}
              duration={2.75}
              prefix="$"
              redraw
              preserveValue={true}
              className={winMoneyClass()}
            />
          </Grid>
          <Grid>
            <hr
              style={{
                marginTop: "15px",
                marginLeft: "-15px",
                direction: "vertical",
                color: "#00caef",
                backgroundColor: "#00caef",
                width: "0.5px",
                height: "132px",
                borderColor: "#00caef",
                opacity: 0.5,
              }}
            />
          </Grid>
          <Grid>
            <img
              alt="coins"
              src={coins}
              style={{
                marginTop: "15px",
                marginLeft: "-5px",
                width: "99px",
                height: "72px",
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          style={{ marginTop: "-74px" }}
        >
          <Grid item xs={2}>
            <img
              alt="skull"
              src={skull}
              style={{ marginLeft: "6px", width: "68.5px", height: "75px" }}
            />
          </Grid>
          <Grid item xs={6} style={{ marginTop: "-33px", marginLeft: "-20px" }}>
            <div style={{ display: "inline" }}>
              <LiquidProgressBar
                progress={
                  groupGoal
                    ? Math.floor(
                        100 * ((groupGoal - nextKillForEarning) / groupGoal)
                      )
                    : 0
                }
              />
            </div>
          </Grid>
          <Grid className={nextKillProfit()}>
            <CountUp
              start={0}
              end={nextProfit()}
              duration={2.75}
              prefix="$"
              redraw
              preserveValue={true}
            />
            <div className="killsleft">
              {groupGoal &&
                `${groupGoal - nextKillForEarning}/${groupGoal} Knockdowns Left`}
            </div>
          </Grid>
          <Grid className="totalrev">
            <CountUp
              start={0}
              end={balance}
              duration={2.75}
              prefix="$"
              redraw
              preserveValue={true}
              className={balanceClass()}
            />
            <CountUp
              start={0}
              end={stacked}
              duration={2.75}
              prefix="/$"
              redraw
              preserveValue={true}
              className="stackedbalance"
            />
            <div className="streamrev">
              <div>Stream</div>
              Revenue
            </div>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default NewBar;
