import React, { useState, useEffect, useRef } from "react";
import Card from "@material-ui/core/Card";
import PaymentModal from "../PaymentModal";
import Fade from "react-reveal/Fade";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AnimatedText from "../AnimatedText";
import "./loading.css";
import { Redirect } from "react-router";
import {
  TextField,
  Grid,
  ThemeProvider,
  Slider,
  Typography,
} from "@material-ui/core";
import KillsIcon from "@material-ui/icons/MyLocation";
import WinIcon from "@material-ui/icons/Star";
import TopBar from "../Menu";
import { useStyles } from "./styles";
import { theme } from "./styles";
import { SERVER_URL } from "../../commom/const";
import howToImage from "../../images/howtounit.png";

const EVENT_KILLS = 0;
const DEFAULT_BUTTONS_KILLS = [2, 4, 10];
const DEFAULT_BUTTONS_WINS = [10, 15, 30];

const sliderProps = {
  1: {
    max: 1,
    min: 1,
    marks: [{ label: "1 Win", value: 1 }],
  },
  0: {
    max: 100,
    min: 1,
    marks: [
      { label: "1", value: 1 },
      { label: "50", value: 50 },
      { label: "100", value: 100 },
    ],
  },
};

const EndorsePage = ({ match }) => {
  const [openModal, setOpenModal] = useState(false);
  const [activeEvent, setActiveEvent] = useState(0);
  const [viewer, setViewer] = useState("");

  const [viewerError, setViewerError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [perEventError, setPerEventError] = useState(false);
  const [totalEventError, setTotalEventError] = useState(false);

  const [message, setMessage] = useState("");
  const [amountPerEvent, setAmountPerEvent] = useState(1);
  const [totalEvents, setTotalEvents] = useState(1);
  const [isValidUser, setIsValidUSer] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const myRef = useRef(null);
  const classes = useStyles();
  const nickname = match.params.id;

  useEffect(() => {
    const checkIFExists = async (nick) => {
      const resp = await fetch(`${SERVER_URL}/exists/${nick}`);
      if (resp.ok) {
        setIsValidUSer(true);
      } else {
        setIsValidUSer(false);
      }
      setLoaded(true);
    };
    checkIFExists(nickname);
  }, [nickname]);

  const isKillsEvent = activeEvent === EVENT_KILLS;
  const defultButtons = isKillsEvent
    ? DEFAULT_BUTTONS_KILLS
    : DEFAULT_BUTTONS_WINS;
  if (loaded && !isValidUser) return <Redirect to="/offline" />;
  return (
    <ThemeProvider theme={theme}>
      <div ref={myRef} className={classes.app}>
        <div className={classes.root}>
          <TopBar />
        </div>
        <div className="nick">{nickname}</div>
        <Grid container spacing={10} className="centerUnit">
          <Grid item xs="auto">
            <Card style={{ background: "transparent", width: "100%" }}>
              <CardMedia component="img" image={howToImage} />
            </Card>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "0vh", marginTop: "-2%" }}
        >
          {isValidUser ? (
            <Grid item md={3} className="centergrid">
              <Fade>
                <Card style={{ width: "120%", minWidth: "300px" }}>
                  <CardContent>
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        <TextField
                          required
                          error={viewerError}
                          helperText={
                            viewerError &&
                            "Nickname has to contain 1-11 characters"
                          }
                          className={classes.input}
                          onChange={(event) => {
                            const nick = event.target.value;
                            if (nick.length < 11) setViewerError(false);
                            setViewer(nick);
                          }}
                          size="small"
                          id="outlined-basic"
                          label="Nickname"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Tabs
                          className={classes.tabs}
                          value={activeEvent}
                          onChange={(event, newValue) => {
                            setActiveEvent(newValue);
                            setAmountPerEvent(1);
                            setTotalEvents(1);
                          }}
                          variant="fullWidth"
                          indicatorColor="primary"
                          textColor="primary"
                        >
                          <Tooltip
                            placement="top"
                            title="Challenge the streamer with the amount of knockdowns of your choice!"
                          >
                            <Tab
                              style={{ borderStyle: "solid!important" }}
                              icon={<KillsIcon />}
                              label="Knockdown"
                            />
                          </Tooltip>
                          <Tooltip
                            placement="top"
                            title="Challenge the streamer to win the game!"
                          >
                            <Tab icon={<WinIcon />} label="Win" />
                          </Tooltip>
                        </Tabs>
                      </Grid>
                      <Typography
                        style={{ marginBottom: "10px", fontWeight: "bold" }}
                        variant="subtitle2"
                      >
                        {`Challenge ${nickname} Per ${
                          isKillsEvent ? "Knockdown" : "Win"
                        }:`}
                      </Typography>
                      <Grid container>
                        {defultButtons.map((amount) => (
                          <Grid item xs={3}>
                            <Button
                              onClick={() => setAmountPerEvent(amount)}
                              size="small"
                              variant="contained"
                              color="primary"
                            >
                              {`$${amount}`}
                            </Button>
                          </Grid>
                        ))}
                        <Grid item xs={3} style={{ marginTop: "-3%" }}>
                          <TextField
                            size="small"
                            helperText={`Per ${
                              isKillsEvent ? "Knockdown" : "Win"
                            }`}
                            label="$"
                            type="number"
                            error={perEventError}
                            id="formatted-numberformat-input"
                            autoFocus={true}
                            value={amountPerEvent}
                            onChange={(event) => {
                              const newVal = parseInt(event.target.value);
                              if (
                                newVal >= 2 &&
                                newVal <= 10000 &&
                                Number.isInteger(newVal)
                              ) {
                                setPerEventError(false);
                              }
                              setAmountPerEvent(newVal);
                            }}
                            InputProps={{
                              inputProps: {
                                min: 2,
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: "bold" }}
                      >
                        {`Number of ${isKillsEvent ? "Knockdowns" : "Wins"}:`}
                      </Typography>
                      <Grid
                        container
                        justify="center"
                        alignItems="center"
                        spacing={6}
                      >
                        {!activeEvent && (
                          <Grid item xs={8} style={{ marginTop: "0%" }}>
                            <Slider
                              value={
                                typeof totalEvents === "number"
                                  ? totalEvents
                                  : 0
                              }
                              defaultValue={2}
                              aria-labelledby="discrete-slider-restrict"
                              step={1}
                              onChange={(evt, number) => setTotalEvents(number)}
                              min={sliderProps[activeEvent].min}
                              max={sliderProps[activeEvent].max}
                              valueLabelDisplay="auto"
                              marks={sliderProps[activeEvent].marks}
                            />
                          </Grid>
                        )}
                        <Grid
                          item
                          xs={3}
                          style={{ marginTop: "-1%", marginRight: "3%" }}
                        >
                          <TextField
                            value={totalEvents}
                            id="standard-number"
                            error={totalEventError}
                            helperText={`${
                              isKillsEvent
                                ? "Number of Knockdowns"
                                : "Next Win Only"
                            }`}
                            onChange={(event) => {
                              const newValue = parseInt(event.target.value);
                              if (
                                newValue <= sliderProps[activeEvent].max &&
                                newValue >= sliderProps[activeEvent].min &&
                                Number.isInteger(newValue)
                              ) {
                                setTotalEventError(false);
                              }
                              setTotalEvents(newValue);
                            }}
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              inputProps: {
                                min: sliderProps[activeEvent].min,
                                max: sliderProps[activeEvent].max,
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} style={{ marginTop: "-5%" }}>
                          <TextField
                            error={messageError}
                            className="textline"
                            id="standard-multiline-flexible"
                            label="Type your message"
                            multiline={false}
                            onChange={(event) => {
                              const message = event.target.value;
                              if (message.length < 130) setMessageError(false);
                              setMessage(message);
                            }}
                            rows="1"
                            fullWidth
                            helperText={
                              messageError
                                ? "Message must be under 130 chars"
                                : "Your message will appear when the streamer completes your challenge."
                            }
                            margin="normal"
                          />
                        </Grid>
                      </Grid>
                      <Grid container justify="center" alignItems="center" />
                      <Grid item xs={10} style={{ marginBottom: "2%" }}>
                        {amountPerEvent && totalEvents ? (
                          <AnimatedText
                            text={`${totalEvents} ${
                              isKillsEvent ? "Knockdowns" : "Wins"
                            } For $${amountPerEvent * totalEvents}`}
                            constant
                          />
                        ) : null}
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Grid container alignItems="center" justify="center">
                      <Grid item xs={4} style={{ marginTop: "-3%" }}>
                        <Button
                          size="large"
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            // Validations
                            if (viewer.length < 1 || viewer.length > 11)
                              setViewerError(true);
                            else if (message.length > 129)
                              setMessageError(true);
                            else if (
                              totalEvents > sliderProps[activeEvent].max ||
                              totalEvents < sliderProps[activeEvent].min ||
                              !Number.isInteger(totalEvents)
                            )
                              setTotalEventError(true);
                            else if (
                              amountPerEvent > 10000 ||
                              amountPerEvent < 1 ||
                              !Number.isInteger(amountPerEvent)
                            )
                              setPerEventError(true);
                            else {
                              setOpenModal(true);
                            }
                          }}
                        >
                          Endorse
                        </Button>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Fade>
            </Grid>
          ) : (
            <Grid item md={3} className="centergrid">
              <div className="lds-hourglass" />
            </Grid>
          )}
        </Grid>
        {isValidUser && (
          <Typography variant="subtitle2">
            {`IBetcha's BETA - Transactions are non refundable`}
          </Typography>
        )}
        {openModal && (
          <PaymentModal
            nickname={nickname}
            open={openModal}
            amount={amountPerEvent * totalEvents}
            goal={totalEvents}
            message={message}
            viewer={viewer}
            eventType={activeEvent}
            handleClose={() => {
              setOpenModal(false);
            }}
          />
        )}
      </div>
    </ThemeProvider>
  );
};

export default EndorsePage;
